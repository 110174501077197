import {useRouter} from 'next/router'
import React, {ReactNode, useState} from 'react'
import {graphql} from 'relay-runtime'
import {ConfirmAlert} from '../../../../Common/Modal/ConfirmAlert'
import {storePath} from '../../../../Common/Page/WithBasicStoreLive'
import {useAppPathFn} from '../../../../Common/Tools/UseAppPathFn'
import {useGQLMutation} from '../../../../Common/Tools/UseGQLMutation'
import {useGlobalToasts} from '../../../../Common/WithGlobalToasts'
import {useFUser} from '../../../../Common/WithUser'
import {NotDeletedPost, postLabel} from '../../Post'
import {DeletePostMutation} from './__generated__/DeletePostMutation.graphql'

const mutation = graphql`
  mutation DeletePostMutation($args: PostDeleteArgs!, $userId: String!) {
    postDelete(args: $args) {
      ...Post_post
    }
  }
`

export const DeletePost = React.memo(
  ({
    post,
    toStoreHomeOnDone,
    children,
  }: {
    post: NotDeletedPost
    toStoreHomeOnDone: boolean
    children: (handler: () => void) => ReactNode
  }) => {
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [commit, isRunning] = useGQLMutation<DeletePostMutation>(
      mutation,
      'soft'
    )
    const {userId} = useFUser()
    const {infoToast} = useGlobalToasts()
    const pathFn = useAppPathFn()
    const router = useRouter()

    const handleDelete = () => {
      commit({
        variables: {
          args: {userId: post.store.user.userId, postId: post.postId},
          userId,
        },
        onCompleted: () => {
          infoToast('Deleted')
          if (toStoreHomeOnDone) {
            void router.push(pathFn(storePath(post.store).root))
          }
        },
      })
    }
    return (
      <>
        {children(() => setIsAlertOpen(true))}
        <ConfirmAlert
          title="Confirm Delete"
          confirmText="Delete"
          isRunning={isRunning}
          isWarning
          onConfirm={handleDelete}
          onCancel={() => setIsAlertOpen(false)}
          open={isAlertOpen}
        >
          Are you sure you want to delete this{' '}
          {postLabel(post)}?
        </ConfirmAlert>
      </>
    )
  }
)
