import { EditorContent } from '@tiptap/react'
import format from 'date-fns/format'
import React, { ReactNode } from 'react'
import { PublishedPost } from '../../../Post/Post'
import { PrimaryActionFloatMenu } from '../../PrimaryActionFloatMenu'
import { useScrollTo } from '../../UseScrollTo'
import { EditorRenderProps } from '../Editor'
import { Comments } from '../../Comments/Comments'
import { Comments_comments$key } from '../../Comments/__generated__/Comments_comments.graphql'
import { FullPageEditorDisplayMenuOnNarrow } from './FullPageEditorDisplayMenuOnNarrow'
import { FullPageEditorDisplayMenuOnWide } from './FullPageEditorDisplayMenuOnWide.tsx'

export const FullPageEditorDisplay = React.memo(
  ({
    propsFromEditor,
    children,
    post,
    commentsKey,
  }: {
    propsFromEditor: EditorRenderProps
    children: ReactNode
    post: PublishedPost
    commentsKey: Comments_comments$key
  }) => {
    const handleScrollTo = useScrollTo()

    return (
      <>
        <div
          className="body-grid-content grid  mb-28"
          style={{grid: 'auto auto 1fr / auto'}}
        >
          {children}
          <div className="mb-5 z-10">
            <EditorContent editor={propsFromEditor.editor} />
          </div>

          <Comments
            className="z-0"
            target={post}
            commentsKey={commentsKey}
            labelSize={
              propsFromEditor.type === 'fullPage' ? 'text-2xl' : 'text-lg'
            }
          />
        </div>
        <div className="body-grid-footer-positioned">
          <FullPageEditorDisplayMenuOnNarrow
            post={post}
            onScrollToComments={() => handleScrollTo('comments')}
          />
          <PrimaryActionFloatMenu />
        </div>
        <div className="body-grid-right top-0">
          <FullPageEditorDisplayMenuOnWide
            post={post}
            onScrollToComments={() => handleScrollTo('comments')}
          />
        </div>
      </>
    )
  }
)
