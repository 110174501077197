import {useRouter} from 'next/router'
import React, {ReactNode, useState} from 'react'
import {graphql} from 'relay-runtime'
import {ConfirmAlert} from '../../../../Common/Modal/ConfirmAlert'
import {storePath} from '../../../../Common/Page/WithBasicStoreLive'
import {useAppPathFn} from '../../../../Common/Tools/UseAppPathFn'
import {useGQLMutation} from '../../../../Common/Tools/UseGQLMutation'
import {useGlobalToasts} from '../../../../Common/WithGlobalToasts'
import {useFUser} from '../../../../Common/WithUser'
import {prependToPostsConnection} from '../../../Store/StoreHome/Posts/StoreHomePosts'
import {isPublishedPost, NotDeletedPost, postLabel} from '../../Post'
import {UnpublishPostMutation} from './__generated__/UnpublishPostMutation.graphql'

const mutation = graphql`
  mutation UnpublishPostMutation($args: PostUnpublishArgs!, $userId: String!) {
    postUnpublish(args: $args) {
      ...Post_post
    }
  }
`

export const UnpublishPost = React.memo(
  ({
    post,
    toStoreHomeOnDone,
    children,
  }: {
    post: NotDeletedPost
    toStoreHomeOnDone: boolean
    children: (handler: (() => void) | null) => ReactNode
  }) => {
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [commit, isRunning] = useGQLMutation<UnpublishPostMutation>(
      mutation,
      'soft'
    )
    const {userId} = useFUser()
    const {infoToast} = useGlobalToasts()
    const pathFn = useAppPathFn()
    const router = useRouter()

    const handleUnpublish = () => {
      const postKey = {userId: post.store.user.userId, postId: post.postId}
      commit({
        variables: {args: postKey, userId},
        updater: cache => prependToPostsConnection(cache, postKey),
        // Note, onCompleted and onError can run on an unmounted component
        // because publishing can remove the article from a filtered list.
        onCompleted: () => {
          infoToast('Unpublished')
          setIsAlertOpen(false)
          // eslint-disable-next-line promise/no-promise-in-callback
          if (toStoreHomeOnDone) {
            void router.push(pathFn(storePath(post.store).root))
          }
        },
        onError: () => setIsAlertOpen(false),
      })
    }

    return (
      <>
        {children(
          post.__typename !== 'ShortPost' && isPublishedPost(post)
            ? () => setIsAlertOpen(true)
            : null
        )}
        <ConfirmAlert
          title="Confirm Unpublish"
          confirmText="Unpublish"
          isRunning={isRunning}
          isWarning={false}
          onConfirm={handleUnpublish}
          onCancel={() => setIsAlertOpen(false)}
          open={isAlertOpen}
        >
          Are you sure you want to unpublish this {postLabel(post)}?
        </ConfirmAlert>
      </>
    )
  }
)
