import {faEllipsisH, faHeart} from '@fortawesome/pro-light-svg-icons'
import {faHeart as faHeartSolid} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {assert, fGet, ReplaceWithFGet} from '@indieocean/utils'
import format from 'date-fns/format'
import _ from 'lodash'
import Head from 'next/head'
import React, {useState} from 'react'
import {useFragment, useLazyLoadQuery} from 'react-relay'
import {graphql} from 'relay-runtime'
import {GQL} from '../../../../Generated/GQL/TypesFromSDL'
import StorePage from '../../../Common/Page/StorePage'
import {storePath} from '../../../Common/Page/WithBasicStoreLive'
import {AppLink} from '../../../Common/Tools/AppLink'
import {MenuButton} from '../../../Common/Tools/MenuButton'
import {useGlobalToasts} from '../../../Common/WithGlobalToasts'
import {assertFound, throwNotFound} from '../../../Common/WithRelay'
import {useUser} from '../../../Common/WithUser'
import {CompactEditorDisplay} from '../../Common/Editor/CompactEditorDisplay/CompactEditorDisplay'
import {Editor} from '../../Common/Editor/Editor'
import {
  Comments,
  commentsQueryArgs,
} from '../../Common/Comments/Comments'
import {PrimaryActionFloatMenu} from '../../Common/PrimaryActionFloatMenu'
import {useScrollTo} from '../../Common/UseScrollTo'
import {PostActionBarMenu} from '../../Post/Common/PostActionBar/PostActionBarMenu'
import {PostToggleLike} from '../../Post/Common/PostActionBar/PostToggleLike'
import {SharePostOnTwitter} from '../../Post/Common/PostActionBar/SharePostOnTwitter'
import {postPath} from '../../Post/Post'
import {usePostSlugOrIdURLParam} from '../UsePostSlugOrIdURLParam'
import {useStoreSlugURLParam} from '../UseStoreSlugURLParam'
import {ShortPostQuery} from './__generated__/ShortPostQuery.graphql'
import {
  ShortPost_query$data,
  ShortPost_query$key,
} from './__generated__/ShortPost_query.graphql'

const query = graphql`
  query ShortPostQuery(
    $postSlug: String
    $postId: String
    $storeSlug: String!
    $userId: String
    $commentsStartCursor: String!
    $commentsPageSize: Int!
  ) {
    ...StorePage_query
    ...ShortPost_query
  }
`

export const ShortPost = React.memo(() => {
  const user = useUser()
  const storeSlug = useStoreSlugURLParam()
  const data = useLazyLoadQuery<ShortPostQuery>(query, {
    ...usePostSlugOrIdURLParam(),
    storeSlug,
    userId: user?.userId,
    ...commentsQueryArgs,
  })

  return (
    <StorePage header={{type: 'store', size: 'grid3-wide'}} queryDataKey={data}>
      <_AfterData queryDataKey={data} />
    </StorePage>
  )
})

export const queryFragment = graphql`
  fragment ShortPost_query on Query {
    store(slug: $storeSlug) {
      id
      data {
        post(slug: $postSlug, postId: $postId) {
          __typename
          ... on ShortPost {
            ...ShortPost_post @relay(mask: false)
            postData: data {
              stage {
                __typename
                ... on ShortPostStagePublished {
                  comments {
                    ...Comments_comments
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

graphql`
  fragment ShortPost_post on ShortPost {
    id
    __typename
    postId
    postKey {
      userId
      postId
    }
    store {
      id
      ...WithBasicStoreLive_store @relay(mask: false)
    }
    createdTime
    # Rename because relay complained that slug from Blog post has competing type (String, not String!)
    postSlug: slug
    postData: data {
      lastEditedTime
      stage {
        __typename
        title
        publishedTime
        likes {
          ...Post_likes @relay(mask: false)
        }
        comments {
          id
          count
        }
      }
      content
      snippet
      books {
        id
        ...Book_book @relay(mask: false)
      }
      stores {
        id
        ...WithBasicStoreLive_store @relay(mask: false)
      }
    }
  }
`

export type ShortPost = GQL.ShortPost_Post
export type NotDeletedShortPost = ReplaceWithFGet<ShortPost, 'postData'>
export const isNotDeletedShortPost = (x: ShortPost): x is NotDeletedShortPost =>
  x.postData !== null

export const shortPostPath = (x: ShortPost) =>
  storePath(x.store).shortPost({postSlug: x.postSlug})

function _commentsKey(data: ShortPost_query$data) {
  const post = fGet(data.store?.data?.post)
  assert(post.__typename === 'ShortPost')
  const stage = fGet(post.postData?.stage)
  assert(stage.__typename === 'ShortPostStagePublished')
  return stage.comments
}

const _AfterData = React.memo(
  ({queryDataKey}: {queryDataKey: ShortPost_query$key}) => {
    const data = useFragment(queryFragment, queryDataKey)
    const {store} = data as unknown as GQL.ShortPost_Query
    assert(store && store.data)
    if (!store.data.post) throwNotFound()
    const {post} = store.data
    assertFound(post.__typename === 'ShortPost')
    assertFound(isNotDeletedShortPost(post))
    const {title} = post.postData.stage

    const [initialData] = useState(post.postData)

    const pageTitle = `"${_.truncate(title, {length: 50})}" by ${
      post.store.name
    } on IndieOcean`

    const user = useUser()
    const isOwn = user?.userId === post.store.user.userId


    useScrollTo()

    return (
      <div className="body-grid3-wide">
        <div className="body-grid-content">
          <Head>
            <title>{pageTitle}</title>
          </Head>
          <Editor
            userIdOfPostAuthor={post.postKey.userId}
            data={initialData}
            editable={false}
            type="compact"
            isExternalSaved
          >
            {props => (
              <>
                <h1 className="text-lg  font-semibold">{title}</h1>
                <h2 className="lighten mb-3 text-xs">
                  {format(post.postData.stage.publishedTime * 1000, 'MMMM d, yyyy')}
                </h2>
                <CompactEditorDisplay propsFromEditor={props} />
              </>
            )}
          </Editor>
          <div className=" flex ">
            <PostToggleLike post={post}>
              {handleLike => (
                <button className="text-lg pl-1 pr-2 py-3" onClick={handleLike}>
                  <FontAwesomeIcon
                    className="text-lg mr-2"
                    icon={
                      post.postData.stage.likes.relativeToStore?.isLiked
                        ? faHeartSolid
                        : faHeart
                    }
                  />
                </button>
              )}
            </PostToggleLike>
            <AppLink className="text-lg pr-4 py-3" path={postPath(post).likes}>
              {post.postData.stage.likes.aggregate.likeCount}
            </AppLink>

            <SharePostOnTwitter post={post}>
              {shareOnTwitterProps => (
                <MenuButton
                  className="text-lg px-5 py-3"
                  origin="bottomStart"
                  overlap
                >
                  <span className="material-icons-outlined text-lg">share</span>
                  {[shareOnTwitterProps]}
                </MenuButton>
              )}
            </SharePostOnTwitter>

            {isOwn && (
              <PostActionBarMenu post={post} toStoreHomeOnAction>
                {menuItems => (
                  <MenuButton
                    className="text-lg px-5 py-3"
                    origin="bottomStart"
                    overlap
                  >
                    <FontAwesomeIcon className="text-xl" icon={faEllipsisH} />
                    {menuItems}
                  </MenuButton>
                )}
              </PostActionBarMenu>
            )}
          </div>

          <div className="mt-5">
            <Comments
              className=""
              commentsKey={_commentsKey(data)}
              target={post}
              labelSize="text-lg"
            />
          </div>
        </div>

        <div className="body-grid-footer-positioned">
          <PrimaryActionFloatMenu />
        </div>
      </div>
    )
  }
)
