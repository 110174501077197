import {faEllipsisH,faComment, faHeart} from '@fortawesome/pro-light-svg-icons'
import { faHeart as faHeartSolid} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import {AppLink} from '../../../../Common/Tools/AppLink'
import {MenuButton} from '../../../../Common/Tools/MenuButton'
import {useUser} from '../../../../Common/WithUser'
import {isPublishedPost, NotDeletedPost, postPath} from '../../Post'
import {PostActionBarMenu} from './PostActionBarMenu'
import {PostToggleLike} from './PostToggleLike'
import {SharePostOnTwitter} from './SharePostOnTwitter'

export const PostActionBar = React.memo(
  ({className = '', post}: {className?: string; post: NotDeletedPost}) => {
    const user = useUser()
    const isOwn = post.postKey.userId === user?.userId
    return (
      <div className={`flex flex-row justify-start items-center ${className}`}>
        {isPublishedPost(post) && (
          <>
            <PostToggleLike post={post}>
              {handleLike => (
                <button className={` pl-1 pr-2`} onClick={handleLike}>
                  <FontAwesomeIcon
                    className="text-lg mr-2"
                    icon={
                      post.postData.stage.likes.relativeToStore?.isLiked
                        ? faHeartSolid
                        : faHeart
                    }
                  />
                </button>
              )}
            </PostToggleLike>
            <AppLink className={`pr-4`} path={postPath(post).likes}>
              {post.postData.stage.likes.aggregate.likeCount}
            </AppLink>

            <AppLink
              className={`flex items-center gap-x-4 px-4`}
              path={postPath(post).root({scrollTo: 'comments'})}
            >
              {<FontAwesomeIcon className="text-lg" icon={faComment} />}
              <h2 className="">{post.postData.stage.comments.count}</h2>
            </AppLink>

            <SharePostOnTwitter post={post}>
              {shareOnTwitterProps => (
                <MenuButton className=" px-3" origin="bottomStart" overlap>
                  <span className="material-icons-outlined text-lg">share</span>
                  {[shareOnTwitterProps]}
                </MenuButton>
              )}
            </SharePostOnTwitter>
          </>
        )}
        {isOwn && (
          <PostActionBarMenu post={post} toStoreHomeOnAction={false}>
            {menuItems => (
              <MenuButton className="px-3" origin="bottomStart" overlap>
                <FontAwesomeIcon className="text-xl" icon={faEllipsisH} />
                {menuItems}
              </MenuButton>
            )}
          </PostActionBarMenu>
        )}
      </div>
    )
  }
)
