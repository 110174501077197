import {useRouter} from 'next/router'
import React, {ReactNode, useState} from 'react'
import {graphql} from 'relay-runtime'
import {ConfirmAlert} from '../../../../Common/Modal/ConfirmAlert'
import {storePath} from '../../../../Common/Page/WithBasicStoreLive'
import {useAppPathFn} from '../../../../Common/Tools/UseAppPathFn'
import {useGQLMutation} from '../../../../Common/Tools/UseGQLMutation'
import {useGlobalToasts} from '../../../../Common/WithGlobalToasts'
import {useFUser} from '../../../../Common/WithUser'
import {prependToPostsConnection} from '../../../Store/StoreHome/Posts/StoreHomePosts'
import {isDraftPost, NotDeletedPost, postLabel} from '../../Post'
import {PublishPostMutation} from './__generated__/PublishPostMutation.graphql'

const mutation = graphql`
  mutation PublishPostMutation($args: PostPublishArgs!, $userId: String!) {
    postPublish(args: $args) {
      ...Post_post
    }
  }
`

export const PublishPost = React.memo(
  ({
    post,
    children,
    prePublish,
    toStoreHomeOnDone,
  }: {
    post: NotDeletedPost
    prePublish: (onDone: (result: 'error' | 'success') => void) => void
    children: (handler: (() => void) | null) => ReactNode
    toStoreHomeOnDone: boolean
  }) => {
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [commit] = useGQLMutation<PublishPostMutation>(mutation, 'soft')
    const {userId} = useFUser()
    const {infoToast} = useGlobalToasts()
    const pathFn = useAppPathFn()
    const router = useRouter()

    const [isRunning, setIsRunning] = useState(false)

    const handlePublish = () => {
      setIsRunning(true)
      prePublish(result => {
        if (result === 'error') {
          setIsRunning(false)
          return
        }
        const postKey = {userId: post.store.user.userId, postId: post.postId}
        commit({
          variables: {args: postKey, userId},
          updater: cache => prependToPostsConnection(cache, postKey),
          // Note, onCompleted and onError can run on an unmounted component
          // because publishing can remove the article from a filtered list. 
          onCompleted: () => {
            infoToast('Published')
            setIsAlertOpen(false)
            setIsRunning(false)
            // eslint-disable-next-line promise/no-promise-in-callback
            if (toStoreHomeOnDone) {
              void router.push(pathFn(storePath(post.store).root))
            }
          },
          onError: () => {
            setIsAlertOpen(false)
            setIsRunning(false)
          },
        })
      })
    }

    return (
      <>
        {children(
          post.__typename !== 'ShortPost' && isDraftPost(post)
            ? () => setIsAlertOpen(true)
            : null
        )}
        <ConfirmAlert
          title="Confirm Publish"
          confirmText="Publish"
          isRunning={isRunning}
          isWarning={false}
          onConfirm={handlePublish}
          onCancel={() => setIsAlertOpen(false)}
          open={isAlertOpen}
        >
          Are you sure you want to publish this {postLabel(post)}?
        </ConfirmAlert>
      </>
    )
  }
)
