import { faFileAlt, faLongArrowAltRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from '@indieocean/apidef'
import React from 'react'
import { AppLink } from '../../../../Common/Tools/AppLink'
import { useUpdateCacheForPost } from '../../../Common/UseUpdateCacheForPost'
import { PostActionBar } from '../../../Post/Common/PostActionBar/PostActionBar'
import { NotDeletedShortPost } from '../../ShortPost/ShortPost'
import { StoreHomeItem } from './StoreHomeItem'
import { StoreHomeTextContentImg } from './StoreHomeTextContentImg'
import { AddTimestamp } from '../../../Common/AddTimestamp'

export const StoreHomeShortPost = React.memo(
  ({
    post,
    className = '',
    singleColumn,
    index,
  }: {
    post: NotDeletedShortPost
    className?: string
    singleColumn: boolean
    index: number
  }) => {
    useUpdateCacheForPost(post)
    const {postSlug} = post
    const {title} = post.postData.stage

    const path = API.Path.store(post.store.slug).shortPost({postSlug}).root()

    const Content = React.memo(({className = ''}: {className?: string}) => (
      <div className={`${className}`}>
        <AppLink path={path}>
          <h2 className="font-semibold">{title}</h2>
          <div
            className={` ${className} overflow-hidden max-h-[4.5em] font-karla relative`}
            style={{lineHeight: '1.5em'}}
          >
            {post.postData.snippet}
            <h2
              className="px-3 absolute bottom-0 right-0 bg-pageBG"
              style={{lineHeight: '1.25em'}}
            >
              <FontAwesomeIcon className="lighten mr-1" icon={faFileAlt} />
              <FontAwesomeIcon className="lighten" icon={faLongArrowAltRight} />
            </h2>
          </div>
        </AppLink>
        <PostActionBar className="mt-2" post={post} />
      </div>
    ))
    return (
      <AddTimestamp
        className={className}
        time={post.postData.stage.publishedTime}
      >
        {singleColumn ? (
          <Content />
        ) : (
          <StoreHomeItem index={index}>
            <StoreHomeTextContentImg path={path} />
            <Content />
          </StoreHomeItem>
        )}
      </AddTimestamp>
    )
  }
)
