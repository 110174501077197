import { API } from '@indieocean/apidef'
import { fGet } from '@indieocean/utils'
import { useURLParam } from '../../../Utils/UseURLParam'
import { GQLFetchError } from '../../Common/WithRelay'

export const usePostSlugOrIdURLParam = () => {
  const postSlug = fGet(useURLParam('postSlug')).toLowerCase()
  if (postSlug.endsWith('_')) {
    const postId = postSlug.substr(0, postSlug.length - 1)
    const decoded = API.Decode.dbId().run(postId)
    if (!decoded.ok) throw new GQLFetchError('NotFound')
    return {postId}
  } else {
    const decoded = API.Decode.genericSlug().run(postSlug)
    if (!decoded.ok) throw new GQLFetchError('NotFound')
    return {postSlug}
  }
}
