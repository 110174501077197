import { API } from '@indieocean/apidef'
import { fGet, noCase } from '@indieocean/utils'
import { useEffect } from 'react'
import { useRelayEnvironment } from 'react-relay'
import { commitLocalUpdate, RecordSourceProxy } from 'relay-runtime'
import { useAssertConst } from '../../Utils/UseAssertConst'
import { BlogPost } from '../Store/BlogPost/BlogPost'
import { ShortPost } from '../Store/ShortPost/ShortPost'
import { Review } from '../Store/Book/Review/Review'

export function updateCacheForPost(
  cache: RecordSourceProxy,
  userId: string,
  postId: string,
  slug: string | null
) {
  const gqlStoreId = API.GQLIds.store({userId})
  const gqlPostId = API.GQLIds.post({userId, postId})
  const storeRecord = fGet(cache.get(gqlStoreId))
  const dataRecord = fGet(storeRecord.getLinkedRecord('data'))
  const postRecord = fGet(cache.get(gqlPostId))
  slug && dataRecord.setLinkedRecord(postRecord, 'post', {slug})
  dataRecord.setLinkedRecord(postRecord, 'post', {postId})
}

export function useUpdateCacheForPost(post: BlogPost | ShortPost | Review) {
  const {userId} = post.store.user
  const {postId} = post
  const slug = (() => {
    switch (post.__typename) {
      case 'BlogPost':
        return post.slug
      case 'ShortPost':
        return post.postSlug
      case 'Review':
        return null
      default:
        noCase(post)
    }
  })()

  const relayEnv = useRelayEnvironment()
  useEffect(() => {
    commitLocalUpdate(relayEnv, cache => {
      updateCacheForPost(cache, userId, postId, slug)
    })
  }, [relayEnv, userId, slug, postId])
  useAssertConst([relayEnv, userId, postId])
}
