import { assert } from '@indieocean/utils'
import React, { ReactNode } from 'react'
import { MenuButtonProps } from '../../../../Common/Tools/MenuButton'
import { useGlobalToasts } from '../../../../Common/WithGlobalToasts'
import { isDraftPost, NotDeletedPost, postPath } from '../../Post'
import { PostAdminActions } from './PostAdminActions'

export const PostActionBarMenu = React.memo(
  ({
    post,
    children,
    toStoreHomeOnAction
  }: {
    post: NotDeletedPost
    children: (props: MenuButtonProps['children'][1]) => ReactNode
    toStoreHomeOnAction:boolean
  }) => {
    const {infoToast} = useGlobalToasts()
    return (
      <PostAdminActions
        post={post}
        toStoreHomeOnAction={toStoreHomeOnAction}
        prePublish={onDone => {
          if (post.postData.snippet.length === 0) {
            infoToast('Cannot publish without a body.')
            onDone('error')
            return
          }
          if (post.__typename === 'BlogPost') {
            assert(isDraftPost(post))
            if (!post.postData.stage.optTitle) {
              infoToast('Cannot publish without a title.')
              onDone('error')
              return
            }
            if (post.postData.stage.optTitle.length <= 4) {
              infoToast('Title is too short.')
              onDone('error')
              return
            }
          }
          onDone('success')
        }}
      >
        {({handleDelete, handlePublish, handleUnpublish}) =>
          children([
            {action: postPath(post).edit, text: `Edit`},
            handlePublish
              ? {action: handlePublish, text: 'Publish'}
              : undefined,
            handleUnpublish
              ? {action: handleUnpublish, text: 'Unpublish'}
              : undefined,
            {action: handleDelete, text: `Delete`},
            ,
          ])
        }
      </PostAdminActions>
    )
  }
)
