import React, {ReactNode} from 'react'
import {NotDeletedPost} from '../../Post'
import {DeletePost} from './DeletePost'
import {PublishPost} from './PublishPost'
import {UnpublishPost} from './UnpublishPost'

export const PostAdminActions = React.memo(
  ({
    post,
    prePublish,
    children,
    toStoreHomeOnAction,
  }: {
    post: NotDeletedPost
    prePublish: (onDone: (result: 'error' | 'success') => void) => void
    toStoreHomeOnAction: boolean
    children: (props: {
      handleDelete: () => void
      handlePublish: (() => void) | null
      handleUnpublish: (() => void) | null
    }) => ReactNode
  }) => {
    return (
      <PublishPost
        post={post}
        prePublish={prePublish}
        toStoreHomeOnDone={toStoreHomeOnAction}
      >
        {handlePublish => (
          <UnpublishPost post={post} toStoreHomeOnDone={toStoreHomeOnAction}>
            {handleUnpublish => (
              <DeletePost post={post} toStoreHomeOnDone={toStoreHomeOnAction}>
                {handleDelete =>
                  children({handleDelete, handleUnpublish, handlePublish})
                }
              </DeletePost>
            )}
          </UnpublishPost>
        )}
      </PublishPost>
    )
  }
)
