import {noCase} from '@indieocean/utils'
import {graphql} from 'relay-runtime'
import {
  BlogPost,
  blogPostPath,
  DraftBlogPost,
  NotDeletedBlogPost,
  PublishedBlogPost,
} from '../Store/BlogPost/BlogPost'
import {
  DraftReview,
  NotDeletedReview,
  PublishedReview,
  Review,
  reviewPath,
} from '../Store/Book/Review/Review'
import {
  NotDeletedShortPost,
  ShortPost,
  shortPostPath,
} from '../Store/ShortPost/ShortPost'

graphql`
  fragment Post_post on Post {
    __typename
    ... on BlogPost {
      ...BlogPost_post @relay(mask: false)
    }
    ... on ShortPost {
      ...ShortPost_post @relay(mask: false)
    }
    ... on Review {
      ...Review_post @relay(mask: false)
    }
  }
`

graphql`
  fragment Post_likes on PostLikes {
    id
    aggregate {
      id
      likeCount
    }
    relativeToStore(userId: $userId) {
      isLiked
    }
  }
`

export type Post = BlogPost | ShortPost | Review
export type NotDeletedPost =
  | NotDeletedBlogPost
  | NotDeletedShortPost
  | NotDeletedReview
export type PublishedPost =
  | PublishedBlogPost
  | NotDeletedShortPost
  | PublishedReview
export type DraftPost = DraftBlogPost | DraftReview

export function isNotDeletedPost(x: BlogPost): x is NotDeletedBlogPost
export function isNotDeletedPost(x: Review): x is NotDeletedReview
export function isNotDeletedPost(x: ShortPost): x is NotDeletedShortPost
export function isNotDeletedPost(x: Post): x is NotDeletedPost
export function isNotDeletedPost(x: Post): x is NotDeletedPost {
  return x.postData !== null
}
export function isPublishedPost(x: BlogPost): x is PublishedBlogPost
export function isPublishedPost(x: Review): x is PublishedReview
export function isPublishedPost(x: ShortPost): x is NotDeletedShortPost
export function isPublishedPost(x: Post): x is PublishedPost
export function isPublishedPost(x: Post): x is PublishedPost {
  if (!isNotDeletedPost(x)) return false
  switch (x.__typename) {
    case 'BlogPost':
      return x.postData.stage.__typename === 'BlogPostStagePublished'
    case 'Review':
      return x.postData.stage.__typename === 'ReviewStagePublished'
    case 'ShortPost':
      return x.postData.stage.__typename === 'ShortPostStagePublished'
    default:
      noCase(x)
  }
}
export function isDraftPost(x: BlogPost): x is DraftBlogPost
export function isDraftPost(x: Review): x is DraftReview
export function isDraftPost(x: ShortPost): x is never
export function isDraftPost(x: Post): x is DraftPost
export function isDraftPost(x: Post): x is DraftPost {
  if (!isNotDeletedPost(x)) return false
  switch (x.__typename) {
    case 'BlogPost':
      return x.postData.stage.__typename === 'BlogPostStageDraft'
    case 'Review':
      return x.postData.stage.__typename === 'ReviewStageDraft'
    case 'ShortPost':
      return false
    default:
      noCase(x)
  }
}

export function postLabel(
  post: Post,
  {addArticle = false}: {addArticle?: boolean} = {}
) {
  switch (post.__typename) {
    case 'BlogPost':
      return `${addArticle ? 'an ' : ''} article`
    case 'Review':
      return `${addArticle ? 'a ' : ''} review`
    case 'ShortPost':
      return `${addArticle ? 'a ' : ''} post`
    default:
      noCase(post)
  }
}

export function postPath(post: NotDeletedPost) {
  switch (post.__typename) {
    case 'BlogPost':
      return blogPostPath(post)
    case 'Review':
      return reviewPath(post)
    case 'ShortPost':
      return shortPostPath(post)
    default:
      noCase(post)
  }
}
