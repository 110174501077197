import {useMemo} from 'react'
import {graphql, useSubscription} from 'react-relay'
import {Post, PublishedPost} from '../../Post'
import {UseSubPostLikesAggregateSubscription} from './__generated__/UseSubPostLikesAggregateSubscription.graphql'

const subscription = graphql`
  subscription UseSubPostLikesAggregateSubscription(
    $userId: String!
    $postId: String!
  ) {
    postLikesAggregate(userId: $userId, postId: $postId) {
      id
      likeCount
    }
  }
`

export function useSubPostLikesAggregate(post: PublishedPost) {
  const {userId} = post.store.user
  const {postId} = post
  useSubscription<UseSubPostLikesAggregateSubscription>(
    useMemo(
      () => ({subscription, variables: {userId, postId}}),
      [postId, userId]
    )
  )
}
