/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UseSubPostLikesAggregateSubscriptionVariables = {
    userId: string;
    postId: string;
};
export type UseSubPostLikesAggregateSubscriptionResponse = {
    readonly postLikesAggregate: {
        readonly id: string;
        readonly likeCount: number;
    };
};
export type UseSubPostLikesAggregateSubscription = {
    readonly response: UseSubPostLikesAggregateSubscriptionResponse;
    readonly variables: UseSubPostLikesAggregateSubscriptionVariables;
};



/*
subscription UseSubPostLikesAggregateSubscription(
  $userId: String!
  $postId: String!
) {
  postLikesAggregate(userId: $userId, postId: $postId) {
    id
    likeCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "postId",
        "variableName": "postId"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "PostLikesAggregate",
    "kind": "LinkedField",
    "name": "postLikesAggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "likeCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UseSubPostLikesAggregateSubscription",
    "selections": (v2/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UseSubPostLikesAggregateSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "570caded89c4a5e24cb41e54140f9566",
    "id": null,
    "metadata": {},
    "name": "UseSubPostLikesAggregateSubscription",
    "operationKind": "subscription",
    "text": "subscription UseSubPostLikesAggregateSubscription(\n  $userId: String!\n  $postId: String!\n) {\n  postLikesAggregate(userId: $userId, postId: $postId) {\n    id\n    likeCount\n  }\n}\n"
  }
};
})();
(node as any).hash = '572dd9ca5a7f6797ec45c14641cd5784';
export default node;
