import {formatDistanceToNowStrict} from 'date-fns'
import React, {ReactNode} from 'react'

export const AddTimestamp = React.memo(
  ({
    time,
    children,
    className = '',
  }: {
    time: number
    children: ReactNode
    className?: string
  }) => {
    return (
      <div className={`${className}`}>
        <h2 className="text-sm lighten-2 text-right">
          {formatDistanceToNowStrict(time * 1000)} ago
        </h2>
        {children}
      </div>
    )
  }
)
