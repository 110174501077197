import {faEllipsisH, faHeart, faComment} from '@fortawesome/pro-light-svg-icons'
import {
  faHeart as faHeartSolid,
} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import {AppLink} from '../../../../Common/Tools/AppLink'
import {MenuButton} from '../../../../Common/Tools/MenuButton'
import {useGlobalToasts} from '../../../../Common/WithGlobalToasts'
import {useUser} from '../../../../Common/WithUser'
import {PostActionBarMenu} from '../../../Post/Common/PostActionBar/PostActionBarMenu'
import {PostToggleLike} from '../../../Post/Common/PostActionBar/PostToggleLike'
import {SharePostOnTwitter} from '../../../Post/Common/PostActionBar/SharePostOnTwitter'
import {postPath, PublishedPost} from '../../../Post/Post'

export const FullPageEditorDisplayMenuOnNarrow = React.memo(
  ({
    post,
    onScrollToComments,
  }: {
    post: PublishedPost
    onScrollToComments: () => void
  }) => {
    const {infoToast} = useGlobalToasts()

    const user = useUser()
    const isOwn = user?.userId === post.store.user.userId
    return (
      <div
        className=" inline-grid grid-flow-col justify-start relative vertNav:hidden
    border-2 border-inputBorder bg-pageBG rounded-full mb-1 ml-2 px-3"
      >
        <PostToggleLike post={post}>
          {handleLike => (
            <button className="text-lg pl-3 pr-2 py-1.5" onClick={handleLike}>
              <FontAwesomeIcon
                className="text-lg"
                icon={
                  post.postData.stage.likes.relativeToStore?.isLiked
                    ? faHeartSolid
                    : faHeart
                }
              />
            </button>
          )}
        </PostToggleLike>
        <AppLink
          className="text-lg pl-2 pr-4 py-1.5"
          path={postPath(post).likes}
        >
          {post.postData.stage.likes.aggregate.likeCount}
        </AppLink>
        <button
          className="text-lg px-4 py-1.5 flex items-center gap-x-2"
          onClick={onScrollToComments}
        >
          <FontAwesomeIcon className="text-lg" icon={faComment} />
          <h2 className="">{post.postData.stage.comments.count}</h2>
        </button>

        <SharePostOnTwitter post={post}>
          {shareOnTwitterProps => (
            <MenuButton
              className="text-lg px-4 py-1.5"
              origin="topStart"
              overlap
            >
              <span className="material-icons-outlined text-lg">share</span>
              {[shareOnTwitterProps]}
            </MenuButton>
          )}
        </SharePostOnTwitter>
        {isOwn && (
          <PostActionBarMenu post={post} toStoreHomeOnAction>
            {menuItems => (
              <MenuButton
                className="text-lg px-5 py-1.5"
                origin="topStart"
                overlap
              >
                <FontAwesomeIcon className="text-xl" icon={faEllipsisH} />
                {menuItems}
              </MenuButton>
            )}
          </PostActionBarMenu>
        )}
      </div>
    )
  }
)
