import {
  assert,
  fGet,
  noCase,
  Replace2,
  ReplaceWithFGet
} from '@indieocean/utils'
import format from 'date-fns/format'
import _ from 'lodash'
import Head from 'next/head'
import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { graphql } from 'relay-runtime'
import { GQL } from '../../../../Generated/GQL/TypesFromSDL'
import StorePage from '../../../Common/Page/StorePage'
import { storePath } from '../../../Common/Page/WithBasicStoreLive'
import { assertFound, throwNotFound } from '../../../Common/WithRelay'
import { useUser } from '../../../Common/WithUser'
import { Editor } from '../../Common/Editor/Editor'
import { commentsQueryArgs } from '../../Common/Comments/Comments'
import { FullPageEditorDisplay } from '../../Common/Editor/FullPageEditorDisplay/FullPageEditorDisplay'
import { useUpdateCacheForPost } from '../../Common/UseUpdateCacheForPost'
import { isPublishedPost } from '../../Post/Post'
import { usePostSlugOrIdURLParam } from '../UsePostSlugOrIdURLParam'
import { useStoreSlugURLParam } from '../UseStoreSlugURLParam'
import { BlogPostQuery } from './__generated__/BlogPostQuery.graphql'
import {
  BlogPost_query$data,
  BlogPost_query$key
} from './__generated__/BlogPost_query.graphql'

const query = graphql`
  query BlogPostQuery(
    $postSlug: String
    $postId: String
    $storeSlug: String!
    $userId: String
    $commentsStartCursor: String!
    $commentsPageSize: Int!
  ) {
    ...StorePage_query
    ...BlogPost_query
  }
`

export const BlogPost = React.memo(() => {
  const user = useUser()
  const storeSlug = useStoreSlugURLParam()
  const data = useLazyLoadQuery<BlogPostQuery>(query, {
    ...usePostSlugOrIdURLParam(),
    storeSlug,
    userId: user?.userId,
    ...commentsQueryArgs,
  })

  return (
    <StorePage header={{type: 'store', size: 'grid3-wide'}} queryDataKey={data}>
      <_AfterData queryDataKey={data} />
    </StorePage>
  )
})

export const queryFragment = graphql`
  fragment BlogPost_query on Query {
    store(slug: $storeSlug) {
      id
      data {
        post(slug: $postSlug, postId: $postId) {
          __typename
          ... on BlogPost {
            ...BlogPost_post @relay(mask: false)
            postData: data {
              stage {
                __typename
                ... on BlogPostStagePublished {
                  comments {
                    ...Comments_comments
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

graphql`
  fragment BlogPost_post on BlogPost {
    id
    postId
    postKey {
      userId
      postId
    }
    store {
      id
      ...WithBasicStoreLive_store @relay(mask: false)
    }
    createdTime
    slug
    postData: data {
      lastEditedTime
      stage {
        __typename
        ... on BlogPostStagePublished {
          publishedTime
          title
          likes {
            ...Post_likes @relay(mask: false)
          }
          comments {
            id
            count
          }
        }
        ... on BlogPostStageDraft {
          optTitle: title
        }
      }
      content
      snippet
      books {
        id
        ...Book_book @relay(mask: false)
      }
      stores {
        id
        ...WithBasicStoreLive_store @relay(mask: false)
      }
    }
  }
`

export type BlogPost = GQL.BlogPost_Post
export type NotDeletedBlogPost = ReplaceWithFGet<BlogPost, 'postData'>
export type PublishedBlogPost = Replace2<
  NotDeletedBlogPost,
  'postData',
  'stage',
  Extract<
    NotDeletedBlogPost['postData']['stage'],
    {__typename: 'BlogPostStagePublished'}
  >
>
export type DraftBlogPost = Replace2<
  NotDeletedBlogPost,
  'postData',
  'stage',
  Extract<
    NotDeletedBlogPost['postData']['stage'],
    {__typename: 'BlogPostStageDraft'}
  >
>

export function blogPostTitle(post: NotDeletedBlogPost) {
  switch (post.postData.stage.__typename) {
    case 'BlogPostStageDraft':
      return post.postData.stage.optTitle
    case 'BlogPostStagePublished':
      return post.postData.stage.title
    default:
      noCase(post.postData.stage)
  }
}

export const blogPostPath = (x: BlogPost) =>
  storePath(x.store).blogPost(x.slug ? {postSlug: x.slug} : {postId: x.postId})

function _commentsKey(data: BlogPost_query$data) {
  const post = fGet(data.store?.data?.post)
  assert(post.__typename === 'BlogPost')
  const stage = fGet(post.postData?.stage)
  assert(stage.__typename === 'BlogPostStagePublished')
  return stage.comments
}

const _AfterData = React.memo(
  ({queryDataKey}: {queryDataKey: BlogPost_query$key}) => {
    const data = useFragment(queryFragment, queryDataKey)

    const {store} = data as unknown as GQL.BlogPost_Query
    assert(store && store.data)
    if (!store.data.post) throwNotFound()
    const {post} = store.data
    assertFound(post.__typename === 'BlogPost')
    useUpdateCacheForPost(post)

    assertFound(isPublishedPost(post))
    const {title} = post.postData.stage

    const pageTitle = `"${_.truncate(title, {length: 50})}" by ${
      post.store.name
    } on IndieOcean`

    return (
      <div className="body-grid3-wide">
        <Head>
          <title>{pageTitle}</title>
        </Head>
        <Editor
          userIdOfPostAuthor={post.store.user.userId}
          data={post.postData}
          editable={false}
          type="fullPage"
          isExternalSaved
        >
          {props => (
            <FullPageEditorDisplay
              propsFromEditor={props}
              post={post}
              commentsKey={_commentsKey(data)}
            >
              <>
                <h1 className="text-3xl vertNav:text-4xl font-bold mt-5">
                  {title}
                </h1>

                <h2 className="lighten mb-5 text-sm">
                  {format(post.postData.stage.publishedTime * 1000, 'MMMM d, yyyy')}
                </h2>
              </>
            </FullPageEditorDisplay>
          )}
        </Editor>
      </div>
    )
  }
)
