import {faTwitter} from '@fortawesome/free-brands-svg-icons'
import {noCase} from '@indieocean/utils'
import React, {ReactNode} from 'react'
import {Config} from '../../../../../Config'
import {storePath} from '../../../../Common/Page/WithBasicStoreLive'
import {MenuButtonItemProps} from '../../../../Common/Tools/MenuButton'
import {assertStateMatch} from '../../../../Common/WithRelay'
import {useUser} from '../../../../Common/WithUser'
import {blogPostPath} from '../../../Store/BlogPost/BlogPost'
import {BookUtils} from '../../../Store/Book/BookUtils'
import {isBookLive} from '../../../Store/Book/Home/Book'
import {shortPostPath} from '../../../Store/ShortPost/ShortPost'
import {PublishedPost} from '../../Post'

export const SharePostOnTwitter = React.memo(
  ({
    post,
    children,
  }: {
    post: PublishedPost
    children: (menuProps: MenuButtonItemProps) => ReactNode
  }) => {
    const user = useUser()
    const isOwn = post.store.user.userId === user?.userId
    return (
      <>
        {children({
          action: `https://twitter.com/intent/tweet?${new URLSearchParams(
            _shareOnTwitterParams(post, isOwn)
          ).toString()}`,
          text: 'Share on Twitter',
          faIcon: faTwitter,
        })}
      </>
    )
  }
)

function _shareOnTwitterParams(post: PublishedPost, isOwn: boolean) {
  switch (post.__typename) {
    case 'BlogPost': {
      const prefix = isOwn
        ? 'My article on IndieOcean:'
        : post.store.data?.links.twitter
        ? `Article by @${post.store.data.links.twitter.username} on IndieOcean:`
        : `Article by ${post.store.name} on IndieOcean:`
      return {
        text: `${Config.client.urls.app(blogPostPath(post).root())}
${prefix}
${post.postData.stage.title}
${post.postData.snippet}`,
        related: 'indieocean_io',
      }
    }
    case 'Review': {
      const book = post.postData.book
      assertStateMatch(isBookLive(book))
      const prefix = isOwn
        ? 'My thoughts on'
        : book.store.data?.links.twitter
        ? `Review by @${book.store.data.links.twitter.username} of`
        : `Review by ${book.store.name} of`

      return {
        text: `${Config.client.urls.app(
          storePath(book.store).books({scrollTo: `book-${book.bookId}`})
        )}
${prefix} ${book.data.info.title} by ${BookUtils.Authors.etAlStr(
          book.data.info.authors
        )}

${post.postData.snippet}`,
        related: 'indieocean_io',
      }
    }
    case 'ShortPost': {
      const prefix = isOwn
        ? 'My post on IndieOcean:'
        : post.store.data?.links.twitter
        ? `Post by @${post.store.data.links.twitter.username} on IndieOcean:`
        : `Post by ${post.store.name} on IndieOcean:`
      return {
        text: `${Config.client.urls.app(shortPostPath(post).root())}
${prefix}
${post.postData.stage.title}
${post.postData.snippet}`,
        related: 'indieocean_io',
      }
    }
    default:
      noCase(post)
  }
}
