import { API } from '@indieocean/apidef'
import React from 'react'
import { AppLink } from '../../../../Common/Tools/AppLink'

export const StoreHomeTextContentImg = React.memo(({path}: {path: API.Path}) => {
  return (
    <AppLink
      path={path}
      className="rounded-md border-pageFG border w-[45px] h-[calc(1.5*45px)]  relative 
    flex flex-col justify-center gap-y-2 items-center"
    >
      <div className="flex flex-col justify-center gap-y-1 items-center w-full">
        <div className="border-b border-inputBorder w-[75%] h-0" />
        <div className="border-b border-inputBorder w-[75%] h-0" />
        <div className="border-b border-inputBorder w-[75%] h-0" />
      </div>
      <div className="flex flex-col justify-center gap-y-1 items-center w-full">
        <div className="border-b border-inputBorder w-[75%] h-0" />
        <div className="border-b border-inputBorder w-[75%] h-0" />
        <div className="border-b border-inputBorder w-[75%] h-0" />
      </div>
    </AppLink>
  )
})
