import {faFileAlt, faLongArrowAltRight} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {assert, noCase} from '@indieocean/utils'
import React from 'react'
import {AppLink} from '../../../../Common/Tools/AppLink'
import {useUpdateCacheForPost} from '../../../Common/UseUpdateCacheForPost'
import {PostActionBar} from '../../../Post/Common/PostActionBar/PostActionBar'
import {BookUtils} from '../../Book/BookUtils'
import {isBookLive} from '../../Book/Home/Book'
import {NotDeletedReview} from '../../Book/Review/Review'
import {StoreHomeItem} from './StoreHomeItem'
import {StoreHomeTextContentImg} from './StoreHomeTextContentImg'
import {AddTimestamp} from '../../../Common/AddTimestamp'

export const StoreHomeReview = React.memo(
  ({
    review,
    className = '',
    singleColumn,
    index,
  }: {
    review: NotDeletedReview
    className?: string
    singleColumn: boolean
    index: number
  }) => {
    useUpdateCacheForPost(review)
    const {book} = review.postData
    assert(isBookLive(book))
    const {title} = book.data.info
    const [path, displayTime] = (() => {
      switch (review.postData.stage.__typename) {
        case 'ReviewStageDraft':
          return [
            BookUtils.path(book).review.edit,
            review.postData.lastEditedTime,
          ] as const
        case 'ReviewStagePublished':
          return [
            BookUtils.path(book).review.root(),
            review.postData.stage.publishedTime,
          ] as const
        default:
          noCase(review.postData.stage)
      }
    })()
    const Content = React.memo(({className = ''}: {className?: string}) => (
      <div className={`${className}`}>
        <AppLink path={path}>
          <h2 className="">
            <span className="font-semibold lighten">Review:</span>{' '}
            <span className="font-semibold">{title}</span>
          </h2>
          <div
            className={` ${className} overflow-hidden max-h-[4.5em] font-karla relative`}
            style={{lineHeight: '1.5em'}}
          >
            {review.postData.snippet.length > 0 ? (
              review.postData.snippet
            ) : (
              <span className="lighten-2">No content yet.</span>
            )}
            <h2
              className="px-3 absolute bottom-0 right-0 bg-pageBG"
              style={{lineHeight: '1.25em'}}
            >
              <FontAwesomeIcon className="lighten mr-1" icon={faFileAlt} />
              <FontAwesomeIcon className="lighten" icon={faLongArrowAltRight} />
            </h2>
          </div>
        </AppLink>

        <PostActionBar className="mt-2" post={review} />
      </div>
    ))
    return (
      <AddTimestamp className={className} time={displayTime}>
        {singleColumn ? (
          <Content />
        ) : (
          <StoreHomeItem index={index}>
            <StoreHomeTextContentImg path={path} />
            <Content />
          </StoreHomeItem>
        )}
      </AddTimestamp>
    )
  }
)
