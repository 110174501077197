import {API} from '@indieocean/apidef'
import {assertFalse, fGet, noCase} from '@indieocean/utils'
import {Switch} from '@material-ui/core'
import _ from 'lodash'
import Head from 'next/head'
import {useRouter} from 'next/router'
import React, {useMemo, useState} from 'react'
import {useLazyLoadQuery, usePaginationFragment} from 'react-relay'
import {graphql, RecordSourceSelectorProxy} from 'relay-runtime'
import {GQL} from '../../../../../Generated/GQL/TypesFromSDL'
import {useURLParam} from '../../../../../Utils/UseURLParam'
import StorePage from '../../../../Common/Page/StorePage'
import {useBasicStoreLive} from '../../../../Common/Page/WithBasicStoreLive'
import {CenteredCircleLoadTrigger} from '../../../../Common/Tools/CenteredCircleLoadTrigger'
import {useAppPathFn} from '../../../../Common/Tools/UseAppPathFn'
import {assertAuthorized} from '../../../../Common/WithRelay'
import {useUser} from '../../../../Common/WithUser'
import {useIsOwnStore} from '../../../Common/UseIsOwnStore'
import {
  isDraftPost,
  isNotDeletedPost,
  isPublishedPost,
} from '../../../Post/Post'
import {NotDeletedBlogPost} from '../../BlogPost/BlogPost'
import {isBookLive} from '../../Book/Home/Book'
import {NotDeletedReview} from '../../Book/Review/Review'
import {
  isNotDeletedShortPost,
  NotDeletedShortPost,
} from '../../ShortPost/ShortPost'
import {useStoreSlugURLParam} from '../../UseStoreSlugURLParam'
import {StoreHomeBase} from '../Base/StoreHomeBase'
import {StoreHomeBlogPost} from '../Common/StoreHomeBlogPost'
import {StoreHomeReview} from '../Common/StoreHomeReview'
import {StoreHomeShortPost} from '../Common/StoreHomeShortPost'
import {StoreHomePostsQuery} from './__generated__/StoreHomePostsQuery.graphql'
import {StoreHomePosts_query$key} from './__generated__/StoreHomePosts_query.graphql'

const pageSize = 20
const query = graphql`
  query StoreHomePostsQuery(
    $storeSlug: String!
    $userId: String
    $pageSize: Int!
    $postsStartCursor: String!
    $drafts: Boolean!
  ) {
    ...StorePage_query
    ...StoreHomePosts_query
  }
`

// This is a grand hack to work around the inability to update connections.
export function prependToPostsConnection(
  cache: RecordSourceSelectorProxy,
  postKey: API.PostKey
) {
  const storeRecord = fGet(cache.get(API.GQLIds.store(postKey)))
  const storeDataRecord = fGet(storeRecord.getLinkedRecord('data'))
  const postsPreprendRecord = storeDataRecord.getLinkedRecords('postsPrepend')
  if (postsPreprendRecord) {
    const postRecord = fGet(cache.get(API.GQLIds.post(postKey)))
    storeDataRecord.setLinkedRecords(
      [postRecord, ...postsPreprendRecord],
      'postsPrepend'
    )
  }
}

export const StoreHomePosts = React.memo(() => {
  const storeSlug = useStoreSlugURLParam()
  const user = useUser()

  const drafts = useURLParam('drafts') === 'true'
  const isOwnStore = useIsOwnStore(storeSlug)
  assertAuthorized(!drafts || isOwnStore)
  const [postsStartCursor] = useState(() => API.GQLTimeCursor.start())

  const data = useLazyLoadQuery<StoreHomePostsQuery>(query, {
    storeSlug,
    pageSize,
    postsStartCursor,
    userId: user?.userId,
    drafts,
  })

  return (
    <StorePage queryDataKey={data} header={{type: 'store', size: 'grid3'}}>
      <StoreHomeBase currentTab="posts">
        <_AfterData queryDataKey={data} />
      </StoreHomeBase>
    </StorePage>
  )
})

export const storeHomePostsfragment = graphql`
  fragment StoreHomePosts_query on Query
  @refetchable(queryName: "StoreHomePosts_Store_Query") {
    store(slug: $storeSlug) {
      id
      data {
        postsPrepend {
          ...Post_post @relay(mask: false)
        }
        posts(first: $pageSize, after: $postsStartCursor, drafts: $drafts)
          @connection(key: "Connection_StoreHomePosts_posts") {
          edges {
            node {
              ...Post_post @relay(mask: false)
            }
          }
        }
      }
    }
  }
`

const _AfterData = React.memo(
  ({queryDataKey}: {queryDataKey: StoreHomePosts_query$key}) => {
    const {
      data: dataIn,
      hasNext,
      loadNext,
    } = usePaginationFragment(storeHomePostsfragment, queryDataKey)

    const dataUnfiltered = dataIn as unknown as GQL.StoreHomePosts_Query

    const drafts = useURLParam('drafts') === 'true'
    const data = useMemo(() => {
      // Filter here, because even though the server won't return it
      // directly, local cache updating can set it to bad states.
      const {posts, postsPrepend} = fGet(dataUnfiltered.store?.data)
      const allPosts = [...postsPrepend, ...posts.edges.map(x => x.node)]
      const filtered = allPosts.filter((x): x is
        | NotDeletedBlogPost
        | NotDeletedReview
        | NotDeletedShortPost =>
        x.__typename === 'BlogPost'
          ? drafts
            ? isDraftPost(x)
            : isPublishedPost(x)
          : x.__typename === 'Review'
          ? (drafts ? isDraftPost(x) : isPublishedPost(x)) &&
            isNotDeletedPost(x) &&
            isBookLive(x.postData.book)
          : x.__typename === 'ShortPost'
          ? isNotDeletedShortPost(x)
          : noCase(x)
      )
      return _.sortBy(
        _.uniqBy(filtered, x => x.postId),
        x =>
          drafts
            ? -x.postData.lastEditedTime
            : isPublishedPost(x)
            ? -x.postData.stage.publishedTime
            : assertFalse()
      )
    }, [dataUnfiltered, drafts])

    const isDrafts = useURLParam('drafts') === 'true'
    const store = useBasicStoreLive()
    const pathFn = useAppPathFn()
    const togglePath = API.Path.store(store.slug).posts({
      drafts: isDrafts ? undefined : 'true',
    })

    const router = useRouter()
    const user = useUser()
    const isOwnStore = user && user.userId === store.user.userId

    return (
      <div className="">
        <Head>
          <title>Posts by {store.name} on IndieOcean</title>
        </Head>
        {isOwnStore && (
          <div className="flex justify-end w-full sticky top-[6.83rem] z-10">
            <div className="flex flex-row justify-start items-center  rounded-lg bg-pageBG bg-opacity-50">
              <h1 className="text-sm pl-4">Drafts</h1>
              <Switch
                checked={isDrafts}
                onChange={() => {
                  void router.push(pathFn(togglePath))
                }}
                color="default"
              />
            </div>
          </div>
        )}

        <div className="flex flex-col gap-y-16 mt-10 mb-10 relative z-0">
          {data.length === 0 && !hasNext && (
            <h2 className="text-center lighten-2">Nothing here yet.</h2>
          )}
          {data.map((x, i) => {
            const props = {key: x.id, index: i, singleColumn: true}
            switch (x.__typename) {
              case 'BlogPost':
                return <StoreHomeBlogPost {...props} post={x} />
              case 'ShortPost':
                return <StoreHomeShortPost {...props} post={x} />
              case 'Review':
                return <StoreHomeReview {...props} review={x} />
              default:
                noCase(x)
            }
          })}
          {hasNext && (
            <div className="relative w-full h-20">
              <CenteredCircleLoadTrigger onTrigger={() => loadNext(pageSize)} />
            </div>
          )}
        </div>
      </div>
    )
  }
)
