import { assert, fGet, Replace2, ReplaceWithFGet } from '@indieocean/utils'
import _ from 'lodash'
import Head from 'next/head'
import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { graphql } from 'relay-runtime'
import { GQL } from '../../../../../Generated/GQL/TypesFromSDL'
import StorePage from '../../../../Common/Page/StorePage'
import { useBasicStoreLive } from '../../../../Common/Page/WithBasicStoreLive'
import { assertFound, assertStateMatch } from '../../../../Common/WithRelay'
import { useUser } from '../../../../Common/WithUser'
import { Editor } from '../../../Common/Editor/Editor'
import { commentsQueryArgs } from '../../../Common/Comments/Comments'
import { FullPageEditorDisplay } from '../../../Common/Editor/FullPageEditorDisplay/FullPageEditorDisplay'
import { useUpdateCacheForPost } from '../../../Common/UseUpdateCacheForPost'
import { isPublishedPost } from '../../../Post/Post'
import { useStoreSlugURLParam } from '../../UseStoreSlugURLParam'
import { BookUtils } from '../BookUtils'
import { isBookLive } from '../Home/Book'
import { useBookSlugOrIdURLParam } from '../UseBookSlugOrIdURLParam'
import { ReviewTop } from './ReviewTop'
import { ReviewQuery } from './__generated__/ReviewQuery.graphql'
import {
  Review_query$data,
  Review_query$key
} from './__generated__/Review_query.graphql'

const query = graphql`
  query ReviewQuery(
    $storeSlug: String!
    $bookSlug: String
    $bookId: String
    $userId: String
    $commentsStartCursor: String!
    $commentsPageSize: Int!
  ) {
    ...StorePage_query
    ...Review_query
  }
`

export const Review = React.memo(() => {
  const userId = useUser()?.userId
  const storeSlug = useStoreSlugURLParam()
  const data = useLazyLoadQuery<ReviewQuery>(query, {
    storeSlug,
    ...useBookSlugOrIdURLParam(),
    userId,
    ...commentsQueryArgs,
  })

  return (
    <StorePage queryDataKey={data} header={{type: 'store', size: 'grid3-wide'}}>
      <_AfterData queryDataKey={data} />
    </StorePage>
  )
})

const queryFragment = graphql`
  fragment Review_query on Query {
    store(slug: $storeSlug) {
      id
      data {
        book(slug: $bookSlug, bookId: $bookId) {
          id
          data {
            __typename
            ... on BookDataLive {
              review {
                ...Review_post @relay(mask: false)
                postData: data {
                  stage {
                    __typename
                    ... on ReviewStagePublished {
                      comments {
                        ...Comments_comments
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

graphql`
  fragment Review_post on Review {
    # Get typename explicitly because this might be used from within a book
    # (as opposed to a list of posts) where it is not natural to query for
    # __typename because the only kind of post that can show up there is a
    # review.
    __typename
    id
    postId
    postKey {
      userId
      postId
    }
    store {
      id
      ...WithBasicStoreLive_store @relay(mask: false)
    }
    createdTime
    postData: data {
      lastEditedTime
      stage {
        __typename
        ... on ReviewStagePublished {
          publishedTime
          likes {
            ...Post_likes @relay(mask: false)
          }
          comments {
            id
            count
          }
        }
      }
      book {
        ...Book_book @relay(mask: false)
      }
      content
      snippet
      books {
        ...Book_book @relay(mask: false)
      }
      stores {
        ...WithBasicStoreLive_store @relay(mask: false)
      }
    }
  }
`

export type Review = GQL.Review_Post
export type NotDeletedReview = ReplaceWithFGet<Review, 'postData'>
export type PublishedReview = Replace2<
  NotDeletedReview,
  'postData',
  'stage',
  Extract<
    NotDeletedReview['postData']['stage'],
    {__typename: 'ReviewStagePublished'}
  >
>
export type DraftReview = Replace2<
  NotDeletedReview,
  'postData',
  'stage',
  Extract<NotDeletedReview['postData']['stage'], {__typename: 'ReviewStageDraft'}>
>

export const reviewPath = (review: NotDeletedReview) =>
  BookUtils.path(review.postData.book).review

function _commentsKey(data: Review_query$data) {
  const book = fGet(data.store?.data?.book?.data)
  assert(book.__typename === 'BookDataLive')
  const review = fGet(book.review)
  assert(review.__typename === 'Review')
  const stage = fGet(review.postData?.stage)
  assert(stage.__typename === 'ReviewStagePublished')
  return stage.comments
}

const _AfterData = React.memo(
  ({queryDataKey}: {queryDataKey: Review_query$key}) => {
    const data = useFragment(queryFragment, queryDataKey)
    const {store} = data as unknown as GQL.Review_Query
    assert(store && store.data) // Guaranteed by StorePage.
    assertFound(store.data.book)
    assertFound(store.data.book.data.__typename === 'BookDataLive')
    assertFound(store.data.book.data.review)
    const {review} = store.data.book.data
    assertFound(isPublishedPost(review))
    const {book} = review.postData
    assertStateMatch(isBookLive(book))
    useUpdateCacheForPost(review)

    const basicStore = useBasicStoreLive()

    const titleTruncated = _.truncate(book.data.info.title, {length: 30})
    const pageTitle = `Review of "${titleTruncated}" from ${basicStore.name}  on IndieOcean`

    return (
      <div className="body-grid3-wide">
        <Head>
          <title>{pageTitle}</title>
        </Head>
        <Editor
          userIdOfPostAuthor={review.store.user.userId}
          data={review.postData}
          editable={false}
          type="fullPage"
          isExternalSaved
        >
          {props => (
            <FullPageEditorDisplay
              propsFromEditor={props}
              post={review}
              commentsKey={_commentsKey(data)}
            >
              <ReviewTop
                review={review}
                publishedTime={review.postData.stage.publishedTime}
              />
            </FullPageEditorDisplay>
          )}
        </Editor>
      </div>
    )
  }
)
