import {faEllipsisH, faHeart, faComment} from '@fortawesome/pro-light-svg-icons'
import {
  faHeart as faHeartSolid,
} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import {AppLink} from '../../../../Common/Tools/AppLink'
import {MenuButton} from '../../../../Common/Tools/MenuButton'
import {useGlobalToasts} from '../../../../Common/WithGlobalToasts'
import {useUser} from '../../../../Common/WithUser'
import {PostActionBarMenu} from '../../../Post/Common/PostActionBar/PostActionBarMenu'
import {PostToggleLike} from '../../../Post/Common/PostActionBar/PostToggleLike'
import {SharePostOnTwitter} from '../../../Post/Common/PostActionBar/SharePostOnTwitter'
import {postPath, PublishedPost} from '../../../Post/Post'

export const FullPageEditorDisplayMenuOnWide = React.memo(
  ({
    post,
    onScrollToComments,
  }: {
    post: PublishedPost
    onScrollToComments: () => void
  }) => {
    const user = useUser()
    const isOwn = user?.userId === post.store.user.userId
    const {infoToast} = useGlobalToasts()
    return (
      <div
        className={` top-5 body-grid-content-vert-pad gap-y-4 sticky hidden vertNav:grid justify-center
        `}
      >
        <div className="py-2  text-xl flex flex-col ">
          <PostToggleLike post={post}>
            {handleLike => (
              <button className="  pt-4 pb-1 w-[50px]" onClick={handleLike}>
                <FontAwesomeIcon
                  icon={
                    post.postData.stage.likes.relativeToStore?.isLiked
                      ? faHeartSolid
                      : faHeart
                  }
                />
              </button>
            )}
          </PostToggleLike>
          <AppLink
            className="text-sm a-btn  pt-1 pb-4 w-[50px]"
            path={postPath(post).likes}
          >
            {post.postData.stage.likes.aggregate.likeCount}
          </AppLink>
          <button
            className=" text-xl py-4 w-[50px]"
            onClick={onScrollToComments}
          >
            <FontAwesomeIcon icon={faComment} />
            <h2 className="text-sm pt-1 ">{post.postData.stage.comments.count}</h2>
          </button>

          <SharePostOnTwitter post={post}>
            {shareOnTwitterProps => (
              <MenuButton className=" py-4" origin="bottomEnd" overlap>
                <span className="material-icons-outlined text-lg">share</span>
                {[shareOnTwitterProps]}
              </MenuButton>
            )}
          </SharePostOnTwitter>
          {isOwn && (
            <PostActionBarMenu post={post} toStoreHomeOnAction>
              {menuItems => (
                <MenuButton
                  className="py-4 w-[50px]"
                  origin="bottomEnd"
                  overlap
                >
                  <FontAwesomeIcon className="text-xl" icon={faEllipsisH} />
                  {menuItems}
                </MenuButton>
              )}
            </PostActionBarMenu>
          )}
        </div>
      </div>
    )
  }
)
