import format from 'date-fns/format'
import Link from 'next/link'
import React from 'react'
import { Cover } from '../../../../Common/Cover'
import { useAppPathFn } from '../../../../Common/Tools/UseAppPathFn'
import { GQLFetchError } from '../../../../Common/WithRelay'
import { BookUtils } from '../BookUtils'
import { isBookLive } from '../Home/Book'
import { NotDeletedReview } from './Review'

export const ReviewTop = React.memo(
  ({
    review,
    publishedTime,
  }: {
    review: NotDeletedReview
    publishedTime: number | null
  }) => {
    const pathFn = useAppPathFn()
    const {book} = review.postData
    if (!isBookLive(book)) throw new GQLFetchError('STATE_MISMATCH')
    const {title, subtitle, cover, authors, purchasingDetails} = book.data.info
    return (
      <div className="">
        <h1 className="text-3xl vertNav:text-4xl font-bold  ">{title}</h1>
        {subtitle && (
          <h1 className="text-base0 vertNav:text-lg font-normal lighten mb-1">
            {subtitle}
          </h1>
        )}
        <h2 className="text-lg font-bold">
          <span className="lighten-2 text-base">by</span>{' '}
          {BookUtils.Authors.toStr(authors)}{' '}
        </h2>

        {publishedTime && (
          <h2 className="lighten mt-2 text-sm">{format(publishedTime * 1000, 'MMMM d, yyyy')}</h2>
        )}
        <div className="flex flex-col justify-center items-center my-10">
          <Cover
            cover={cover}
            size="w-[200px]"
            href={BookUtils.inlinePath(book)}
          />
          <Link href={pathFn(BookUtils.path(book).root())}>
            <a>
              <button className="btn-dark btn-md mt-3">
                Buy{' '}
                {purchasingDetails && (
                  <span className="text-base">
                    {BookUtils.Price.format(
                      BookUtils.Price.lower(purchasingDetails)
                    )}
                  </span>
                )}
              </button>
            </a>
          </Link>
        </div>
      </div>
    )
  }
)
