import {fGet} from '@indieocean/utils'
import {ReactNode} from 'react'
import {graphql} from 'relay-runtime'
import {useCreateStoreOrAction} from '../../../../Common/Tools/UseCreateStoreOrAction'
import {useGQLMutation} from '../../../../Common/Tools/UseGQLMutation'
import {useGlobalToasts} from '../../../../Common/WithGlobalToasts'
import {PublishedPost} from '../../Post'
import {useSubPostLikesAggregate} from './UseSubPostLikesAggregate'
import {
  PostToggleLikeMutation,
  PostToggleLikeMutationResponse,
} from './__generated__/PostToggleLikeMutation.graphql'
import {
  PostToggleLikeUndoMutation,
  PostToggleLikeUndoMutationResponse,
} from './__generated__/PostToggleLikeUndoMutation.graphql'

const likeMutation = graphql`
  mutation PostToggleLikeMutation($args: PostLikeArgs!, $userId: String!) {
    postLike(args: $args) {
      id
      aggregate {
        id
        likeCount
      }
      relativeToStore(userId: $userId) {
        isLiked
      }
    }
  }
`

const undoLikeMutation = graphql`
  mutation PostToggleLikeUndoMutation($args: PostLikeArgs!, $userId: String!) {
    postUndoLike(args: $args) {
      id
      aggregate {
        id
        likeCount
      }
      relativeToStore(userId: $userId) {
        isLiked
      }
    }
  }
`

export function PostToggleLike({
  post,
  children,
}: {
  post: PublishedPost
  children: (handleToggleLike: () => void) => ReactNode
}) {
  const [commitLike] = useGQLMutation<PostToggleLikeMutation>(
    likeMutation,
    'soft'
  )
  const [commitUndoLike] = useGQLMutation<PostToggleLikeUndoMutation>(
    undoLikeMutation,
    'soft'
  )
  useSubPostLikesAggregate(post)

  const {infoToast} = useGlobalToasts()

  const handleToggleLike = useCreateStoreOrAction(user => {
    if (user.userId === post.store.user.userId) {
      infoToast("That's your own post!")
      return
    }
    const {likes} = post.postData.stage
    const {isLiked} = fGet(likes.relativeToStore)
    const targetKey = post.postKey
    const variables = {
      args: {userId: user.userId, targetKey},
      userId: user.userId,
    }

    const likeResponse: PostToggleLikeMutationResponse = {
      postLike: {
        id: likes.id,
        aggregate: {
          id: likes.aggregate.id,
          likeCount: likes.aggregate.likeCount + 1,
        },
        relativeToStore: {isLiked: true},
      },
    }

    const undoLikeResponse: PostToggleLikeUndoMutationResponse = {
      postUndoLike: {
        id: likes.id,
        aggregate: {
          id: likes.aggregate.id,
          likeCount: likes.aggregate.likeCount - 1,
        },
        relativeToStore: {isLiked: false},
      },
    }

    isLiked
      ? commitUndoLike({
          variables,
          optimisticResponse: undoLikeResponse,
          onCompleted: () => {},
        })
      : commitLike({
          variables,
          optimisticResponse: likeResponse,
          onCompleted: () => {},
        })
  })

  return <>{children(handleToggleLike)}</>
}
