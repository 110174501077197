import {faFileAlt, faLongArrowAltRight} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {API} from '@indieocean/apidef'
import {noCase} from '@indieocean/utils'
import React from 'react'
import {AppLink} from '../../../../Common/Tools/AppLink'
import {useUpdateCacheForPost} from '../../../Common/UseUpdateCacheForPost'
import {PostActionBar} from '../../../Post/Common/PostActionBar/PostActionBar'
import {blogPostTitle, NotDeletedBlogPost} from '../../BlogPost/BlogPost'
import {StoreHomeItem} from './StoreHomeItem'
import {StoreHomeTextContentImg} from './StoreHomeTextContentImg'
import {AddTimestamp} from '../../../Common/AddTimestamp'

export const StoreHomeBlogPost = React.memo(
  ({
    post,
    className = '',
    singleColumn,
    index,
  }: {
    post: NotDeletedBlogPost
    className?: string
    singleColumn: boolean
    index: number
  }) => {
    useUpdateCacheForPost(post)
    const postSlugOrId = post.slug
      ? {postSlug: post.slug}
      : {postId: post.postId}
    const title = blogPostTitle(post)

    const [path, displayTime] = (() => {
      const postPath = API.Path.store(post.store.slug).blogPost(postSlugOrId)
      switch (post.postData.stage.__typename) {
        case 'BlogPostStageDraft':
          return [postPath.edit, post.postData.lastEditedTime] as const
        case 'BlogPostStagePublished':
          return [postPath.root(), post.postData.stage.publishedTime] as const
        default:
          noCase(post.postData.stage)
      }
    })()
    const Content = React.memo(({className = ''}: {className?: string}) => (
      <div className={`${className}`}>
        <AppLink path={path}>
          <h2 className="">
            <span className="font-semibold lighten">Article:</span>{' '}
            <span className="font-semibold">{title ?? 'Untitled'}</span>
          </h2>
          <div
            className={` ${className} overflow-hidden max-h-[4.5em] font-karla relative`}
            style={{lineHeight: '1.5em'}}
          >
            {post.postData.snippet.length > 0 ? (
              post.postData.snippet
            ) : (
              <span className="lighten-2">No content yet.</span>
            )}
            <h2
              className="px-3 absolute bottom-0 right-0 bg-pageBG"
              style={{lineHeight: '1.25em'}}
            >
              <FontAwesomeIcon className="lighten mr-1" icon={faFileAlt} />
              <FontAwesomeIcon className="lighten" icon={faLongArrowAltRight} />
            </h2>
          </div>
        </AppLink>

        <PostActionBar className="mt-2" post={post} />
      </div>
    ))

    return (
      <AddTimestamp time={displayTime} className={className} >
        {singleColumn ? (
          <Content />
        ) : (
          <StoreHomeItem  index={index}>
            <StoreHomeTextContentImg path={path} />
            <Content />
          </StoreHomeItem>
        )}
      </AddTimestamp>
    )
  }
)
