/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Post_likes = {
    readonly id: string;
    readonly aggregate: {
        readonly id: string;
        readonly likeCount: number;
    };
    readonly relativeToStore: {
        readonly isLiked: boolean;
    } | null;
    readonly " $refType": "Post_likes";
};
export type Post_likes$data = Post_likes;
export type Post_likes$key = {
    readonly " $data"?: Post_likes$data;
    readonly " $fragmentRefs": FragmentRefs<"Post_likes">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Post_likes",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PostLikesAggregate",
      "kind": "LinkedField",
      "name": "aggregate",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "likeCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "userId"
        }
      ],
      "concreteType": "PostLikesRelativeToStore",
      "kind": "LinkedField",
      "name": "relativeToStore",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isLiked",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PostLikes",
  "abstractKey": null
};
})();
(node as any).hash = 'a836afb711f6243ba1fa5703b870bc26';
export default node;
